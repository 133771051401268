<template>
    <section class="min-w1600">
      <div class="searchwrap">
        <div class="searchBar">
           <div class="box-ui-select searchbar-content">
             <div class="title"><span>{{ $t('common.monthly') }}</span></div>
             <div class="fx">
                <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" :startDefault="startDefault" :dateProps="dateProps" />
                <button class="btn-search ml-5" type="button" @click="pageSeach">
                  <i class="fas fa-search"></i>
                </button>
                <select v-model="reqData.vendor" class="ml-10">
                  <option value="" selected>{{ $t('common.all') }}</option>
                  <template v-for="item in vendorList" :key="item.code">
                    <option :value="item.code">{{item.text}}</option>
                  </template>
                </select>
             </div>
           </div>

           <a class="btn-table" @click="onChangeType('table')">{{ $t('button.tableType') }}</a>
           <a class="btn-table" @click="onChangeType('graph')">{{ $t('button.graphType') }}</a>
        </div>
      </div>
      <div class="main-contents-wrapper">
        <h2 class="tableName">{{ $t('common.total') }}</h2>
        <div class="table-wrapper">
           <table class="mainTable">
             <thead>
                <tr>
                  <th>{{ $t('searchArea.date') }}</th> <!-- 날짜 -->
                    <th>{{ $t('table.head.totalBetMoney') }}</th> <!-- 총베팅금 -->
                    <th>{{ $t('table.head.totalWinMoney') }}</th> <!-- 총당첨금 -->
                    <th>{{ $t('table.head.wl') }}</th> <!-- 윈루즈 -->
                    <th>{{ $t('table.head.feeRate') }} ( {{ $t('table.head.std00') }} )</th> <!-- 수수료율 -->
                    <th>{{ $t('table.head.sumSubPt') }}</th> <!-- 합계 누적 서브포인트 -->
                </tr>
             </thead>
             <tbody>
             <tr>
               <td>
                 {{totalData ? totalData.day : ''}}
               </td>
               <td>{{ thousand(totalData && totalData.betAmt ? totalData.betAmt: 0) }}</td>
               <td>{{ thousand(totalData && totalData.betWinAmt ? totalData.betWinAmt: 0) }}</td>
               <td>{{ thousand(totalData && totalData.winLose ? totalData.winLose: 0) }}</td>
               <td>{{ thousand(totalData && totalData.baseRate ? totalData.baseRate: 0) }}%</td>
               <td>{{ thousand(totalData && totalData.creditSubPoint ? totalData.creditSubPoint : 0) }}</td>
             </tr>
             </tbody>
           </table>
        </div>

        <div v-if="type === 'table'"> <!-- 테이블 형 -->

          <h2 class="tableName">{{ $t('common.subptlist') }}</h2>
          <div class="table-wrapper">
             <table class="mainTable">
              <thead>
              <tr>
                <th>{{ $t('searchArea.date') }}</th> <!-- 날짜 -->
                <th>{{ $t('table.head.totalBetMoney') }}</th> <!-- 총베팅금 -->
                <th>{{ $t('table.head.totalWinMoney') }}</th> <!-- 총당첨금 -->
                <th>{{ $t('table.head.wl') }}</th> <!-- 윈루즈 -->
                <th>{{ $t('table.head.feeRate') }} ( {{ $t('table.head.std00') }} )</th> <!-- 수수료율 -->
                <th>{{ $t('table.head.sumSubPt') }}</th> <!-- 합계 누적 서브포인트 -->
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, idx) in dayList" :key="idx">
                <tr>
                  <td>{{ item.day }}</td>
                  <td>{{ thousand(item.betAmt) }}</td>
                  <td>{{ thousand(item.betWinAmt) }}</td>
                  <td>{{ thousand(item.winLose) }}</td>
                  <td>{{ thousand(item.baseRate) }}%</td>
                  <td>{{ thousand(item.creditSubPoint) }}</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div><!-- // 테이블 형 -->

        <div v-if="type === 'graph'"> <!-- 그래프 형 -->
          <div class="chart_spc">
            <div>
              <h2 class="tableName">{{ $t('common.subpt_scalar') }}</h2>
              <div class="chart">
                <apexchart width="700" height="500" type="bar" :options="barChartOptions" :series="barSeries"></apexchart>
              </div>
            </div>
            <div>
              <h2 class="tableName">{{ $t('common.subpt_rate') }}</h2>
              <div class="chart">
                <apexchart width="700" height="500" type="pie" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div><!-- // 그래프 형-->
      </div>
    </section>
  </template>

  <script>
  import SearchFilter from '@/components/common/SearchFilter'
  import DateSelector from '@/components/common/DateSelector_monthly'
  import subTitle from '@/components/main/PageSubTitle'
  import { reportDaily, getCode } from '@/api/member.js'
  import { GAME_INFO_LIST, GAME_CODE_NAME } from '@/libs/constants'
  import { replaceDateT, numberWithCommas, getDateStr, thousand } from '@/libs/utils.js'
  import Pagination from '@/components/common/Pagination'
  import { format } from 'date-fns'
  import { getVendorList } from '@/api/common'
  import { getSubpointListByDaily } from '@/api/partner'

  export default {
    name: 'subptlistdaily',
    components: {
      SearchFilter,
      DateSelector,
      subTitle,
      Pagination
    },
    data () {
      return {
        type: 'table',
        dateProps: {
          start: true,
          end: false
        },
        reqData: {
          day: '',
          vendor: ''
        },
        dayList: [],
        totalData: {},
        tableData: [],
        days: [],
        weeks: [],
        startDefault: '',
        series: [],
        chartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          dataLabels: {
            style: {
              colors: ['#353535']
            }
          },
          labels: [],
          colors: ['#A1CCD1', '#E9B384', '#7C9D96', '#B89FEC'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        barSeries: [],
        barChartOptions: {
          chart: {
            width: 380,
            type: 'pie',
          },
          plotOptions: {
            bar: {
              distributed: true,
              columnWidth: '80%'
            }
          },
          xaxis: {
            categories: []
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return thousand(val)
            }
          },
          dataLabels: {
            style: {
              colors: ['#353535']
            }
          },
          labels: [],
          colors: ['#A1CCD1', '#E9B384', '#7C9D96', '#B89FEC'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        },
        vendorList: []
      }
    },
    methods: {
      thousand,
      onChangeType (type) {
        this.type = type
      },
      async pageSeach () {
        await this.setTableData()
      },
      getDate (date) {
        return date
          .toISOString()
          .split('T')[0]
          .split('-')
          .map(v => Number(v))
      },
      setStartDate (date) {
        // console.log(date)
        let _date = ''
        if (!date) {
          _date = new Date()
          _date.setDate(1)
        } else {
          _date = date[0]
        }
        this.startDefault = _date
        this.reqData.day = getDateStr(_date, 'yyyy-MM')
      },
      async setTableData () {
        this.emitter.emit('Loading', true)
        console.log(this.reqData)
        const res = await getSubpointListByDaily(this.reqData)
        console.log('reportDaily : ', res)
        this.dayList = res.data.list
        this.totalData = res.data.totalData
        this.totalDataByVendor = res.data.totalDataByVendor

        if (this.reqData.vendor === '') {
          this.series = []
          this.chartOptions.labels = []

          this.barSeries = [{
            name: 'SubPoint',
            data: []
          }]

          this.totalDataByVendor.forEach(item => {
            const vendor = item.vendor ? this.getVendorName(item.vendor) : 'ETC'
            //const subPoint = item.creditSubPoint < 0 ? 0 : item.creditSubPoint
            this.series.push(item.creditSubPoint < 0 ? 0 : item.creditSubPoint)



            this.chartOptions.labels.push(vendor)


            this.barSeries[0].data.push(item.creditSubPoint)
            this.barChartOptions.xaxis.categories.push(vendor)
          })
        }

        this.emitter.emit('Loading', false)
      }
    },
    async created () {
      this.emitter.emit('Loading', true)
      const vendorList = await getVendorList()

      this.vendorList = []
      vendorList.forEach(item => {
        this.vendorList.push({
          code: item,
          text: this.getVendorName(item)
        })
      })
      await this.setStartDate()
      await this.setTableData()
      this.emitter.emit('Loading', false)
    }
  }
  </script>

  <style scoped>
  .tableName {margin: 10px 0;}
  .btn-table {height: 26px;background: #353535;color: #fff;margin-left: 15px;display: flex;align-items: center;padding: 0 10px;cursor: pointer;font-size: 14px;}
  .main-sub-data-item {
    flex: 1 1 0;
    margin-right: 10px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 3px 3px 12px 0px #d1d1d1;
    display: flex;
  }
  .main-sub-data-item > div {
    padding: 8px 0;
    text-align: center;
    box-sizing: border-box;
  }
  .sub-data-item-title {
    background: #ab2926;
    color: #fff;
    font-weight: 700;
    width: 35%;
  }
  .sub-data-item-content {
    font-weight: 700;
    text-align: right;
    width: 65%;
  }
  .main-sub-data-item:last-child {
    margin-right: 0;
  }
  .page-contents-wrap {
    margin-bottom: 10px;
    display: flex;
  }
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .btn-total-data {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    background: #2f4858;
    width: fit-content;
    border-radius: 3px;
    border: 2px solid #2f4858;
  }
  .btn-total-data:hover {
    color: #2f4858;
    background: #f1f1e6;
  }
  .btn-total-data > i {
    margin-right: 5px;
  }
  .btn-total-data > span {
    font-weight: 700;
    letter-spacing: 2px;
  }
  .mainTable{
    table-layout: fixed;
  }
  .mainTable td {
    position: relative;
  }
  .week_day {
    position: absolute;
    left: 4px;
    top: 4px;
    font-weight: 900;
  }
  .week_no {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 4px;
  }
  .chart {display: flex;gap: 15px;margin-bottom: 15px;flex-wrap: wrap;}

  .chart_spc {
    display: flex;
    gap: 30px;
  }
  .chart_spc > div {width: 48%;}
  .chart > div {}
  </style>
